import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { appConst } from '../../AppConst';
import notify from 'devextreme/ui/notify';
import { CreateAuthorizedStore } from '../../helpers/data';
import {
  DataGrid, Column, Selection, SearchPanel, HeaderFilter, Editing,
} from 'devextreme-react/data-grid';

export function Explorer(props) {
  let explorerGridRef = null;

  const campaignsDS = CreateAuthorizedStore(
    {
      loadUrl: `${window.env.apiEndpoint}/api/v1/DistributionList/GetCampaigns`,
      deleteUrl: `${window.env.apiEndpoint}/api/v1/DistributionList/DeleteCampaign`
    }, null, 'Id');

  const { visible, filter } = props;

  return (
    <>
      <Popup
        visible={visible}
        title="Emails..."
        height="75%"
        width="75%"
        onHiding={
          () => {
            props.setShowExplorer(false);
          }
        }
        onShowing={
          (e) => {
            if (typeof (filter) === 'undefined') return;
            explorerGridRef.instance.option('filterValue', filter);
            explorerGridRef.instance.refresh();
          }
        }
      >
        <DataGrid
          id="explorerGrid"
          ref={node => { explorerGridRef = node; }}
          {...appConst.defaultGridOptions}
          remoteOperations={false}
          height="100%"
          dataSource={campaignsDS}
          onRowDblClick={(e) => {
            const ds = CreateAuthorizedStore(
              { loadUrl: `${window.env.apiEndpoint}/api/v1/DistributionList/GetCampaignHtml` },
              { load: { Id: e.key} },
            );
            ds.load().then(
              (data) => {
                props.updateHtmlEditorValue(data[0].Html);
                props.setShowExplorer(false);
              },
              (error) => {
                notify({ message: error.message, shading: true }, 'error');
              }
            );
          }}
        >
          <Editing
            allowAdding={false}
            allowDeleting={true}
            allowUpdating={false}
          />
          <Selection mode="single" />
          <SearchPanel
            visible
            width={240}
            placeholder="Search..."
          />
          <HeaderFilter
            visible
            allowSearch
          />
          <Column
            dataField="Title"
            dataType="text"
            caption="Title"
          />
          <Column
            dataField="From"
            dataType="text"
            caption="From"
          />
          <Column
            dataField="To"
            dataType="text"
            caption="To"
          />
          <Column
            dataField="FolderName"
            dataType="text"
            caption="Folder"
          />
          <Column
            dataField="Status"
            dataType="text"
            caption="Status"
          />
          <Column
            dataField="CreateDate"
            dataType="datetime"
            caption="Create Date"
            format={appConst.dateAndTimeDisplayFormat}
          />
          <Column
            dataField="SentDate"
            dataType="datetime"
            caption="Sent Date"
            format={appConst.dateAndTimeDisplayFormat}
          />
        </DataGrid>
      </Popup>
    </>
  );
}

