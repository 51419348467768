import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import notify from 'devextreme/ui/notify';
import './external-mail-system.scss';
import { getAuthorizationTokenAsync } from '../../helpers/data';
import { custom } from 'devextreme/ui/dialog';

export default function (props) {
  const { statusMessage, hasError, addNewMode, entityId, entityType } = props;
  const [showLoadingPanel, setShowLoadingPanel] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, DELETE',
  }

  async function showAuditDialog() {
    try {
      setShowLoadingPanel(true);
      const response = await getData(`${window.env.apiEndpoint}/api/v1/DistributionList/Audit`, { Id: entityId, entityType: entityType });
      setShowLoadingPanel(false);
      if (response.ok) {
        const data = JSON.parse(await response.text());
        let dialog = custom({
          title: "Audit Result",
          messageHtml: `<strong><p>Email Count in DB: ${data.EmailsCount}</p></b><p>Email Count in list: ${data.ListCount}</p></b><p>In DB not in list: ${data.InEmailsNotInList}</p></b><p>In list not in DB: ${data.InListNotInEmails}</p></strong>`,
          buttons: [{
            text: "Re-Create",
            hint: "Re-Create the distribution list.",
            onClick: async (e) => {
              try {
                e.component.option("disabled", true);
                setShowLoadingPanel(true);
                const response = await postData(`${window.env.apiEndpoint}/api/v1/ExternalMailSystem/ReCreateDistributionList`, entityId);
                setShowLoadingPanel(false);
                if (response.ok) {
                  notify({ message: 'Re-Create distribution list command has been issued successfully.', shading: true }, 'success');
                }
              } catch (e) {
                notify({ message: `Server Error Status:${e.message}`, shading: true }, 'error');
              }
            }
          },
          {
              text: 'Refresh',
              onClick: async (e) => {
                await showAuditDialog();
              }
          },
          {
            text: "Close",
            onClick: (e) => {
              dialog.hide();
            }
          }
          ]
        });
        dialog.show();
      }
    }
    catch (e) {
      notify({ message: `Server Error Status:${e.message}`, shading: true }, 'error');
    }
  }

  return (
    !addNewMode &&
    <div className="container">
      <div className={hasError ? "errorStatusMessage" : "statusMessage"}>
        {hasError ? "" : "Ok"}
      </div>
      <div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          visible={showLoadingPanel}
        />
        <Button
          visible={hasError}
          text="Retry"
          type="danger"
          hint={statusMessage}
          onClick={async (btn) => {
            try {
              btn.component.option("disabled", true);
              setShowLoadingPanel(true);
              const response = await postData(`${window.env.apiEndpoint}/api/v1/ExternalMailSystem/RetryLastCommand`, `${entityId}#${entityType}`);
              btn.component.option("disabled", false);
              setShowLoadingPanel(false);
              if (response.ok) {
                notify({ message: 'Retry command has been issued successfully.', shading: true }, 'success');
              }
            } catch (e) {
              notify({ message: `Server Error Status:${e.message}`, shading: true }, 'error');
            }
          }}
        />
        <Button
          visible={(entityType === 'CommitteeDistributionList' || entityType === 'CSRRoleDistributionList' || entityType === 'DivisionDistributionList')}
          text="Audit"
          type="success"
          hint="Audit the distribution list."
            onClick={async (btn) => {
              await showAuditDialog();
          }}
        />
      </div>
    </div>
  );

  async function postData(url, bodyString) {
    const token = await getAuthorizationTokenAsync();
    const response = await fetch(url, {
      method: "POST",
      headers: new Headers({
        'Authorization': `Bearer ${token.accessToken}`,
        ...headers,
      }),
      body: JSON.stringify(bodyString),
    });

    return response;
  }

  async function getData(url, parameters) {
    const token = await getAuthorizationTokenAsync();
    const response = await fetch(`${url}?${new URLSearchParams(parameters)}`, {
      method: "Get",
      headers: new Headers({
        'Authorization': `Bearer ${token.accessToken}`,
        ...headers,
      }),
    });

    return response;
  }
}