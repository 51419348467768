import React, { useState } from 'react';
import {
  DataGrid,
  Column,
  Editing,
  Texts,
  Lookup,
  HeaderFilter,
} from 'devextreme-react/data-grid';
import {
  CreateAuthorizedDataSource, CreateAuthorizedStore
} from '../../helpers/data';
import ExternalMailSystem from '../external-mail-system/ExternalMailSystem';
import { appConst } from '../../AppConst';
import { Fragment } from 'react';
import notify from 'devextreme/ui/notify';
import { EmailClient } from '../email-client/email-client';
import { Explorer } from '../email-client/explorer';


export default function (props) {
  const [viewEmailClient, setViewEmailClient] = useState(false);
  const [emailFormData, setEmailFormData] = useState();
  const [viewSentEmails, setViewSentEmails] = useState(false);
  const [sentEmailsFilter, setSentEmailsFilter] = useState([]);

  const emailAddressTypesData = CreateAuthorizedDataSource(
    { loadUrl: `${window.env.apiEndpoint}/api/v1/Members/EmailAddressTypesLookup` },
    null,
    'Value', false,
  );

  const divisionDistributionListData = CreateAuthorizedDataSource(
    {
      loadUrl: `${window.env.apiEndpoint}/api/v1/Divisions/GetDivisionDistributionLists`,
      insertUrl: `${window.env.apiEndpoint}/api/v1/Divisions/PostDivisionDistributionList`,
      updateUrl: `${window.env.apiEndpoint}/api/v1/Divisions/PutDivisionDistributionList`,
      deleteUrl: `${window.env.apiEndpoint}/api/v1/Divisions/DeleteDivisionDistributionList`,
    },
    {
      load: { divisionId: props.divisionId },
      insert: { divisionId: props.divisionId },
      update: { divisionId: props.divisionId },
    },
    'Id', false,
  );

  return (
    <Fragment>
      <DataGrid
        dataSource={divisionDistributionListData}
        key="Id"
        {...appConst.defaultGridOptions}
        onRowUpdating={
          (e) => {
            e.newData = { ...e.oldData, ...e.newData };
          }
        }
      >
        <HeaderFilter
          visible
          allowSearch
        />
        <Editing
          mode="row"
          allowAdding
          allowDeleting
          allowUpdating
        >
          <Texts
            confirmDeleteMessage={"Are you sure to delete this division distribution list."} />
        </Editing>
        <Column
          dataField="EmailAddressTypeId"
          dataType="number"
          caption="Email Address Type"
        >
          <Lookup
            dataSource={emailAddressTypesData.store()}
            valueExpr="Value"
            displayExpr="Text"
          />
        </Column>
        <Column
          dataField="Name"
          dataType="text"
          caption="Name"
          allowEditing={false} />
        <Column
          dataField="StatusMessage"
          cellRender={
            (data) => {
              const row = data.data;
              return <ExternalMailSystem statusMessage={row.StatusMessage} hasError={row.HasError} entityId={row.Id} entityType="DivisionDistributionList" />
            }
          }
        />
        <Column
          type="buttons"
          width={110}
          buttons={[
            {
              name: "delete",
              hint: "Delete division Distribution List",
              icon: 'trash',
            },
            {
            name: "edit",
            hint:"Edit division Distribution List",
            icon: 'edit',
            },
            {
              name: "email",
              hint: "Send Email to Distribution List",
              icon: 'email',
              visible: (e) => {
                return !e.row.isEditing;
              },
              onClick: (e) => {
                const defaultFromEmailDS = CreateAuthorizedStore({ loadUrl: `${window.env.apiEndpoint}/api/v1/DistributionList/GetDefaultFromEmail` }, null, 'Value');
                defaultFromEmailDS.load().then(
                  (data) => {
                    setViewEmailClient(true);
                    setEmailFormData({ email: data[0].fromEmail, name: data[0].fromName, to: e.row.data.Name });
                  },
                  (error) => {
                    notify({ message: error.message, shading: true }, 'error');
                  }
                );
              },
            },
            {
              name: "sentItems",
              hint: "Show sent emails to this distribution list...",
              icon: 'activefolder',
              visible: (e) => {
                return !e.row.isEditing;
              },
              onClick: (e) => {
                setSentEmailsFilter(['To', '=', e.row.data.Name]);
                setViewSentEmails(true);
              }
            }
          ]}
        />
      </DataGrid>
      <EmailClient visible={viewEmailClient} formData={emailFormData} setViewEmailClient={setViewEmailClient} />
      <Explorer visible={viewSentEmails} setShowExplorer={setViewSentEmails} filter={sentEmailsFilter} />
    </Fragment>
  );
}